@mixin make-container() {
  width: 100%;
  padding-right: rem($grid-gutter-width / 2);
  padding-left: rem($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
  @include respond-to-up('lg') {
    max-width: $grid-container;

    &--large {
      max-width: $grid-container-large;
    }

    &--small {
      max-width: 915px;
    }
    @media only screen and (max-width:1670px) {
      max-width: 1400px;
    }
    @media only screen and (max-width:1430px) {
      max-width: 1240px;
    }
    @media only screen and (max-width:1270px) {
      max-width: 992px;
    }
    @media only screen and (max-width:992px) {
      max-width: 100%;
    }
  }
}
