.input-wrapper {
    height: 70px;
    border: 1px solid #D6D6D6;
    border-radius: 7px;
    padding: 5px 15px;
    margin: 8px;
  
    span.input-text {
      font-weight: 600;
      color: #B1B1B1;
    }

    input {
        border: none;
        display: block;
        width: 100%;
        padding: 5px 0;

        &:focus, &:hover {
            outline: 0;
            border: none;
            box-shadow: none;
          }
    }

    select {
        border: none;
        display: block;
        width: 100%;
        padding: 5px 0;

        &:focus, &:hover {
            outline: 0;
            border: none;
            box-shadow: none;
        }
    }
  }