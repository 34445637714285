// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------

//
// Default Typography Settings
//
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: $title-bottom-space;
}

p {
  margin-top: 0;
  margin-bottom: $paragraph-bottom-space;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: $list-bottom-space;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
.hmt-title{
  font-size: 34px;
  font-weight: 300 !important;
  color: #000000;
  margin: 0 0;
  @media only screen and (max-width:992px) {
      font-size: 24px;
  }
  @media only screen and (max-width:680px) {
      font-size: 20px;
  }
}
.mt-title{
  font-size: 34px;
  font-weight: 300 !important;
  color: #000000;
  margin: 0 0;
  @media only screen and (max-width:992px) {
      font-size: 24px;
  }
  @media only screen and (max-width:680px) {
      font-size: 20px;
  }
}