// -----------------------------------------------------------------------------
// Font Face
// -----------------------------------------------------------------------------

// Import Custom Font
//
// @include fontFace("fontName", #{$font-path}/fontName);
// @include fontFace("fontName", #{$font-path}/fontName, 700, italic);
//

// Font Face
// @param {String} $family
// @param {String} $src
// @param {String} $weight
// @param {String} $style


@font-face {
    font-family: 'Aktiv';
    src:url(../fonts/AktivGrotesk-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Aktiv';
    src:url(../fonts/AktivGrotesk-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Aktiv';
    src:url(../fonts/AktivGrotesk-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Aktiv';
    src:url(../fonts/AktivGrotesk-Bold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Maison';
    src:url(../fonts/Maison-Neue-Extended-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Maison';
    src:url(../fonts/Maison-Neue-Extended-Book.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}
*{
    font-family: 'Aktiv', sans-serif;
    font-weight: 400;
}
strong,b{
    font-weight: 600;
}
.maison{
    font-family: "Maison", sans-serif;
    font-weight: 400;
}