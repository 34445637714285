// -----------------------------------------------------------------------------
// Print
// -----------------------------------------------------------------------------
// stylelint-disable declaration-no-important, selector-no-qualifying-type

@if $enable-print-styles {
  @media print {
    // General
    *,
    *::before,
    *::after {
      color: #000 !important; /* [1] */
      text-shadow: none !important;
      background: transparent !important;
      box-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]::after {
      content: " (" attr(href) ")";
    }

    abbr[title]::after {
      content: " (" attr(title) ")";
    }

    // Link
    a[href^="#"]::after,
    a[href^="javascript:"]::after {
      content: "";
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    // Table
    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
  }
}
