// -----------------------------------------------------------------------------
// Reboot
// -----------------------------------------------------------------------------


html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: $font-size-root;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $body-color;
  background-color: $body-bg;
}
