header{
    width: 100%;
    padding: 28px 0;
    background: #FFF;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    @media only screen and (max-width: 992px) {
        padding: 0 0;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .o-container{
        @media only screen and (min-width: 992px) and (max-width: 1280px){
            max-width: 100%;
        }
        @media only screen and (max-width: 992px) {
            padding-right: 0;
        }
    }
    .list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        position: relative;
        @media only screen and (max-width: 992px) {
            justify-content: space-between;
        }
    }
    .center{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
    }
    .main-nav{
        float: left;
        @media only screen and (max-width: 992px) {
            display: none;
        }
        ul{
            margin: 0 0;
            padding: 0 0;
            list-style: none;
        }
        li{
            list-style: none;
            float: left;
            margin-right: 54px;
            position: relative;
            display: flex;
            @media only screen and (max-width:1430px) {
                margin-right: 30px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1200px) {
                margin-right: 15px;
            }
            a{
                text-decoration: none;
                font-size: 13px;
                color: #000000;
                transition: 0.5s;
            }
            &:hover{
                >a{
                    color: #00B4E5;
                }
            }
            .sub-menus{
                position: absolute;
                top: 100%;
                left: 0;
                width: 320px;
                background: #FFFFFF;
                padding: 38px 0 15px 0;
                display: none;
                ul{
                    margin: 0 0;
                    padding: 0 0;
                    li{
                        margin: 0 0;
                        padding: 0 0;
                        width: 100%;
                        &:hover{
                            >a{
                                background: #FBFBFB;
                            }
                        }
                        >a{
                            width: 100%;
                            display: flex;
                            padding: 20px 50px 20px 20px;
                            position: relative;
                            text-decoration: none;
                            background: #FFF;
                            transition: 0.5s;
                            color: #000000;
                            span{
                                font-size: 13px;
                                color: #000000;
                            }
                            .sv{
                                position: absolute;
                                top: 50%;
                                right: 34px;
                                transform: translate(0, -50%);
                                svg{
                                    float: left;
                                }
                            }
                            &:after{
                                content:"";
                                position: absolute;
                                bottom: 0;
                                left: 20px;
                                width: calc(100% - 44px);
                                height: 1px;
                                background: #E5E6E6;
                            }
                        }
                        .last-sub-menu{
                            position: absolute;
                            top: 0;
                            left: 100%;
                            background: #FBFBFB;
                            width: 320px;
                            display: none;
                            ul{
                                li{
                                    a{
                                        transition: 0.5s;
                                        background: none;
                                        &:after{
                                            display: none;
                                        }
                                    }
                                    &:hover{
                                        a{
                                            background: #fff;
                                            color: #000000;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &+.header-logo+.main-nav{
            .sub-menus{
                ul{
                    li{
                        >a{
                            padding: 20px 20px 20px 50px;
                            .sv{
                                left: 24px;
                                svg{
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
                .last-sub-menu{
                    left: -100%;
                }
            }
        }
    }
    .header-logo{
        margin-right: 50px;
        position: relative;
        @media only screen and (max-width:1430px) {
            margin-right: 30px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1200px) {
            margin-right: 15px;
        }
        @media only screen and (max-width: 992px) {
            margin-right: 0;
        }
        svg{
            float: left;
            @media only screen and (max-width: 992px) {
                width: 150px;
                height: auto;
            }
        }
        &+.main-nav{
            li{
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .right{
        float: right;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        position: absolute;
        right: 0;
        svg{
            float: left;
        }
        .search-icon{
            cursor: pointer;
            @media only screen and (max-width: 992px) {
                height: 60px;
                width: 60px;
                background: #ccc;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
            }
        }
        .header-lang{
            margin-left: 15px;
            padding-left: 15px;
            @media only screen and (min-width: 992px) and (max-width: 1200px) {
                margin-left: 0px;
            }
            @media only screen and (max-width: 992px) {
                display: none;
            }
            .lnk-active{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-size: 13px;
                span{
                    margin-right: 13px;
                }
            }
        }
        .mobile-menu-icon{
            display: none;
            span{
                width: 20px;
                height: 2px;
                background: #000000;
                border-radius: 100%;
                transition: 0.5s;
                &:nth-child(2n){
                    margin: 3px 0;
                }
            }
            @media only screen and (max-width: 992px) {
                height: 60px;
                width: 60px;
                padding: 0 20px;
                background: #F5F5F5;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
            }
            &.active{
                span{
                    &:nth-child(1){
                        transform: rotate(45deg);
                        margin-top: 10px;
                    }
                    &:nth-child(2){
                        margin-left: -15px;
                        opacity: 0;
                    }
                    &:nth-child(3){
                        transform: rotate(-45deg);
                        margin-top: -17px;
                    }
                }
            }
        }
    }
}
.full-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    &.active{
        opacity: 1;
        visibility: visible;
    }
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:rgba(0,0,0,0.8);
    }
    .search-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 920px;
        @media only screen and (max-width:992px){
            width: calc(100% - 30px);
        }
    }
    input{
        width: 100%;
        height: 80px;
        border: none;
        outline: none;
        border-radius: 0;
        font-size: 18px;
        font-weight: 400;
        padding: 0 15px;
        font-family: "Aktiv", sans-serif;
        @media only screen and (max-width:992px){
            height: 60px;
            font-size: 16px;
        }
    }
    button{
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border: none;
        border-radius: 0;
        background: #464646;
        width: 90px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width:992px){
            width: 60px;
        }
        svg{
            width: 35px;
            height: auto;
            @media only screen and (max-width:992px){
                width: 20px;
            }
        }
    }
}
.mobile-menu-box{
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background: #F8F8F8;
    display: none;
    z-index: 15;
    padding: 15px 15px 100px 15px;
    &.active{
        display: block;
    }
    .mobile-man-menu{
        height: 100%;
        overflow: auto;
    }
    ul{
        margin: 0 0;
        padding: 0 0;
        li{
            list-style: none;
            a{
                font-size: 13px;
                color: #000000;
                border-bottom: 1px solid #E5E6E6;
                padding: 20px 15px;
                display: block;
                text-decoration: none;
            }
            .sub-menu-item{
                display: none;
            }
            ul{
                li{
                    border-bottom: 1px solid #E5E6E6;
                    a{
                        padding: 10px 20px;
                        display: inline-block;
                        border: none;
                    }
                    &:hover{
                        ul{
                            display: block;
                        }
                    }
                    ul{
                        display: none;
                        li{
                            a{
                                padding: 10px 20px 10px 30px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .mobile-lang{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        a{
            text-decoration: none;
            color: 13px;
            color: #000000;
            padding: 20px 17px;
            &.active{
                color: #00B4E5;
            }
        }
    }
}