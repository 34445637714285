// -----------------------------------------------------------------------------
// Float
// -----------------------------------------------------------------------------
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-float-none {
  float: none;
}
