// -----------------------------------------------------------------------------
// Visibility
// -----------------------------------------------------------------------------

.u-hidden {
  display: none !important;
}

.u-visible {
  display: block !important;
}

.u-is-mobile {
  display: block;

  @include respond-to-up('lg') {
    display: none;
  }
}


.u-is-desktop {
  display: none;

  @include respond-to-up('lg') {
    display: block;
  }
}
