
.mv-1540{
    width: 1540px;
    margin:0px auto;
    padding: 0 15px;
    @media only screen and (max-width:1570px){
        width: 1240px;
    }
    @media only screen and (max-width:1270px){
        width: 100%;
    }
}
.mv-1400{
    width: 1400px;
    margin:0px auto;
    padding: 0 15px;
    @media only screen and (max-width:1430px){
        width: 1240px;
    }
    @media only screen and (max-width:1270px){
        width: 100%;
    }
}
.mv-1200{
    width: 1200px;
    margin:0px auto;
    @media only screen and (max-width:1230px){
        width: 100%;
        padding: 0 15px;
    }
}
.detail-banner{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .img{
        width: 100%;
        height: 340px;
        background-position: center center;
        background-size: cover;
        position: relative;
        @media only screen and (max-width:680px) {
            height: 150px;
        }
        &:before{
            content:"";
            width: 100%;
            height: calc(100% - 40px);
            background: rgb(0,0,0);
            background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            opacity: 0.8;
            position: absolute;
            bottom: 0;
            left: 0;
            @media only screen and (max-width:680px) {
                height: 100%;
            }
        }
    }
    .box{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: absolute;
        bottom: 60px;
        left: 0;
        z-index: 2;
        color: #FFF;
        text-align: center;
        @media only screen and (max-width:992px) {
            padding: 0 15px;
            width: 100%;
        }
        @media only screen and (max-width:680px) {
            bottom: 30px;
        }
    }
    .tps{
        font-size: 12px;
        letter-spacing: 1.2px;
        margin-bottom: 14px;
        @media only screen and (max-width:680px) {
            font-size: 10px;
            margin-bottom: 7px;
        }
        span{
            font-weight: 500;
            margin-right: 30px;
            @media only screen and (max-width:680px) {
                margin-right: 5px;
            }
            &:first-child{
                opacity: 0.7;
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
}
.page-title{
    font-size: 50px;
    font-weight: 400;
    text-align: center;
    p{
        font-weight: 300;
    }
    @media only screen and (max-width:992px) {
        font-size: 32px;
    }
    @media only screen and (max-width:680px) {
        font-size: 18px;
    }
}
.breadcrumb{
    width: 100%;
    border-bottom: 1px solid #CECECE;
    padding: 24px 0;
    position: relative;
    &:before{
        @media only screen and (max-width:992px) {
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 100%;
            background: rgb(255,255,255);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
            z-index: 1;
        }
    }
    .bread-list{
        @media only screen and (max-width:992px) {
            max-width: 100%;
            width: auto;
            float: left;
        }
    }
    ul{
        margin: 0 0;
        padding: 0 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        @media only screen and (max-width:992px) {
            float: right;
            width: auto;
            display: inline-flex;
            flex-wrap: initial;
        }
        li{
            display: flex;
            align-items: center;
            align-content: center;
            margin-right: 15px;
            font-size: 12px;
            color: #000;
            letter-spacing: 1.2px;
            &:after{
                content:"";
                width: 15px;
                height: 2px;
                background: #999999;
                margin-left: 15px;
            }
            a{
                text-decoration: none;
                font-weight: 500;
                color: #000;
                transition: 0.5s;
                opacity: 0.4;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                &:hover{
                    opacity: 1;
                }
            }
            &:last-child{
                margin-right: 0;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                @media only screen and (max-width:992px) {
                    max-width: initial;
                }
                &:after{
                    display: none;
                }
            }
        }
    }
}
.page-detail{
    width: 100%;
    margin: 50px 0;
    @media only screen and (max-width:992px) {
        margin: 30px 0;
    }
    .page-detail-box{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .o-container{
        @media only screen and (max-width:1200px) {
            max-width: 100%;
        }
    }
}

.detail-left-menu {
    width: 20%;
    border-right: 1px solid #ECECEC;
    padding-bottom: 40px;
    padding-right: 40px;
    @media only screen and (max-width:992px) {
        width: 100%;
        padding-bottom: 0;
        padding-right: 0;
        border-right: 0;
    }
    .left-menu-title{
        font-size: 14px;
        color: #000000;
        font-weight: 600;
        margin-bottom: 40px;
        position: relative;
        @media only screen and (max-width:992px) {
            margin-bottom: 0;
            padding: 20px 50px 20px 15px;
            background: #EEEEEE;
        }
        &:before{
            @media only screen and (max-width:992px) {
                content:"";
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translate(0, -50%);
                width: 34px;
                height: 34px;
                background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34'%3E%3Cg id='Group_17086' data-name='Group 17086' transform='translate(-460 -542)'%3E%3Crect id='Rectangle_1965' data-name='Rectangle 1965' width='34' height='34' transform='translate(460 542)' fill='%23fff'/%3E%3Cg id='Icon_feather-menu' data-name='Icon feather-menu' transform='translate(469 554)'%3E%3Cpath id='Path_7032' data-name='Path 7032' d='M4.5,18h16' transform='translate(-4.5 -13)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='Path_7033' data-name='Path 7033' d='M4.5,9h16' transform='translate(-4.5 -9)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='Path_7034' data-name='Path 7034' d='M4.5,27h16' transform='translate(-4.5 -17.001)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat no-repeat;
                background-position: center center;
                background-size: cover;
            }
        }
        &.active{
            &:before{
                @media only screen and (max-width:992px) {
                    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34'%3E%3Cg id='Group_17072' data-name='Group 17072' transform='translate(-350 -18)'%3E%3Crect id='Rectangle_1965' data-name='Rectangle 1965' width='34' height='34' transform='translate(350 18)' fill='%23fff'/%3E%3Cg id='Icon_feather-menu' data-name='Icon feather-menu' transform='translate(358.918 30.124)'%3E%3Cpath id='Path_7033' data-name='Path 7033' d='M0,0H14.323' transform='translate(2.817 -0.389) rotate(45)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='Path_7035' data-name='Path 7035' d='M0,0H14.323' transform='translate(12.945 -0.389) rotate(135)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat no-repeat;
                    background-position: center center;
                    background-size: cover;
                }
            }
        }
    }
    .lft-mn{
        @media only screen and (max-width:992px) {
            display: none;
            margin-top: 20px;
        }
        ul{
            margin: 0 0;
            padding: 0 0;
            list-style: none;
            ul{
                padding: 0 0 0 20px;
                margin: 0 0;
                border-left: 1px solid #DBDBDB;
                margin-top: 15px;
                li{
                    margin-bottom: 0;
                    margin: 4px 0;
                    >a{
                        padding: 4px 0;
                        transition: 0.5s;
                        font-size: 12px;
                        &:before{
                            content:"—";
                            margin-right: 5px;
                        }
                        &:hover{
                            padding: 4px 6px;
                            background: #00B4E5;
                            color: #FFF;
                        }
                    }
                    &.active{
                        >a{
                            padding: 4px 6px;
                            background: #00B4E5;
                            color: #FFF;
                        }
                        ul{
                            li{
                                a{
                                    color: #000;
                                }
                            }
                        }
                    }
                    ul{
                        padding: 0 0 0 10px;
                        li{
                            a{
                                &:hover{
                                    color: #FFF;
                                }
                            }
                            &.active{
                                a{
                                    color: #FFF;
                                }
                            }
                        }
                    }
                }
            }
            li{
                margin-bottom: 40px;
                @media only screen and (max-width:992px) {
                    margin-bottom: 25px;
                }
                @media only screen and (max-width:680px) {
                    margin-bottom: 15px;
                }
                a{
                    text-decoration: none;
                    color: #999999;
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0.7px;
                    transition: 0.5s;
                    &:hover{
                        color: #000;
                    }
                }
                &.active{
                    &>a{
                        color: #000;
                    }
                }
            }
        }
    }
}
.detail-right-content {
    width: 80%;
    padding-left: 40px;
    font-size: 17px;
    line-height: 1.7;
    color: #1B1D27;
    @media only screen and (max-width:992px) {
        width: 100%;
        padding-left: 0;
        padding-top: 30px;
    }
    @media only screen and (max-width:680px) {
        padding-top: 30px;
        font-size: 15px;
    }
    strong{
        font-weight: 500;
        color: #000;
    }
}
.dt-full-text {
    width: 100%;
    margin-bottom: 80px;
    font-weight: 300;
    p{
        font-weight: 300;
    }
    @media only screen and (max-width:992px) {
        margin-bottom: 45px;
    }
    @media only screen and (max-width:680px) {
        margin-bottom: 20px;
    }
}
.dt-full-two-img-to-text{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
    @media only screen and (max-width:992px) {
        margin-bottom: 45px;
    }
    @media only screen and (max-width:680px) {
        margin-bottom: 20px;
    }
    .two-item{
        width: calc(50% - 20px);
        @media only screen and (max-width:680px) {
            width: 100%;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .img{
        width: 100%;
        height: 220px;
        background-position: center center;
        background-size: cover;
        margin-bottom: 30px;
        @media only screen and (max-width:680px) {
            margin-bottom: 15px;
        }
    }
    .small-icon{
        width: 100%;
        margin-bottom: 50px;
        display: flex;
        img{
            float: left;
        }
        @media only screen and (max-width:680px) {
            margin-bottom: 20px;
        }
    }
    .two-item-title{
        width: 100%;
        font-weight: 500;
        margin-bottom: 20px;
        @media only screen and (max-width:680px) {
            margin-bottom: 15px;
        }
    }
    .two-item-desc{
        font-weight: 300;
        p{
            font-weight: 300;
        }
    }
}
.gray-bg-title{
    width: 100%;
    font-size: 28px;
    font-weight: 300;
    margin-bottom: 40px;
    color: #000;
    @media only screen and (max-width:992px) {
        margin-bottom: 30px;
        font-size: 24px;
    }
    @media only screen and (max-width:680px) {
        margin-bottom: 15px;
        font-size: 20px;
    }
    p{
        font-weight: 300;
    }
}
.gray-bg-in-item{
    width: 100%;
    background: #F8F8F8;
    padding: 40px 30px;
    margin-bottom: 60px;
    font-weight: 300;
    p,span,div,label,em{
        font-weight: 300;
    }
    @media only screen and (max-width:992px) {
        padding: 20px 15px;
        margin-bottom: 45px;
    }
    @media only screen and (max-width:680px) {
        margin-bottom: 20px;
        padding: 15px 15px;
    }
    *{
        color: #1B1D27;
    }
    strong,b{
        color: #000;
    }
}
.gray-line{
    width: 100%;
    height: 6px;
    background: #EEEEEE;
    margin: 30px 0 60px 0;
    @media only screen and (max-width:992px) {
        margin: 20px 0;
    }
}
.dt-full-left-and-right-img{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;
    @media only screen and (max-width:992px) {
        margin-bottom: 45px;
    }
    @media only screen and (max-width:680px) {
        margin-bottom: 20px;
    }
    .lf-item{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 80px;
        @media only screen and (max-width:992px) {
            margin-bottom: 45px;
        }
        @media only screen and (max-width:680px) {
            margin-bottom: 20px;
            flex-direction: column-reverse;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .img{
            width: 50%;
            min-height: 440px;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            @media only screen and (max-width:1280px) {
                min-height: 340px;
            }
            @media only screen and (max-width:992px) {
                min-height: 320px;
            }
            @media only screen and (max-width:680px) {
                min-height: 220px;
                width: 100%;
                margin-bottom: 20px;
            }
        }
        .box{
            width: 50%;
            padding-right: 40px;
            @media only screen and (max-width:680px) {
                width: 100%;
                margin-bottom: 15px;
                padding-right: 0;
            }
        }
        .dsc{
            font-weight: 300;
            p{
                font-weight: 300;
            }
        }
        &.to-right{
            @media only screen and (max-width:680px) {
                flex-direction: column;
            }
            .box{
                padding-right: 0;
                padding-left: 40px;
                @media only screen and (max-width:680px) {
                    padding-left: 0;
                }
            }
        }
    }
}
.dt-two-img-gallery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 80px;
    @media only screen and (max-width:992px) {
        margin-bottom: 45px;
    }
    @media only screen and (max-width:680px) {
        margin-bottom: 20px;
    }
    .dt-gal{
        width: calc(50% - 20px);
        height: 500px;
        background-position: center center;
        background-size: cover;
        border: 1px solid #DFDFDF;
        display: flex;
        @media only screen and (max-width:992px) {
            height: 380px;
        }
        @media only screen and (max-width:680px) {
            height: 240px;
            width: 100%;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        a{
            width: 100%;
            height: 100%;
            padding: 30px 30px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            align-content: flex-end;
            @media only screen and (max-width:680px) {
                padding: 15px 15px;
            }
            svg{
                opacity: 0;
                transition: 0.5s;
            }
            &:hover{
                svg{
                    opacity: 1;
                }
            }
        }
    }
}
.dt-values {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    text-align: center;
    margin-bottom: 80px;
    @media only screen and (max-width:992px) {
        margin-bottom: 45px;
    }
    @media only screen and (max-width:680px) {
        margin-bottom: 20px;
    }
    .dt-vl-list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .dt-vl-item{
        font-size: 14px;
        font-weight: 300;
        width: 120px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin: 0 25px;
        p{
            font-weight: 300;
        }
        @media only screen and (max-width:992px) {
            margin: 0 10px;
        }
        @media only screen and (max-width:680px) {
            margin: 0 7px;
            width: 110px;
        }
        .name{
            margin-top: 30px;
        }
        @media only screen and (max-width:992px) {
            margin-bottom: 25px;
        }
        @media only screen and (max-width:680px) {
            margin-bottom: 20px;
        }
    }
}
.p2-title{
    font-size: 34px;
    font-weight: 300;
    margin-bottom: 38px;
    text-align: center;
    p{
        font-weight: 300;
    }
    @media only screen and (max-width:1280px) {
        font-size: 26px;
    }
    @media only screen and (max-width:992px) {
        font-size: 22px;
        margin-bottom: 20px;
    }
    @media only screen and (max-width:680px) {
        margin-bottom: 15px;
        font-size: 20px;
    }
}
.sss-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;
    @media only screen and (max-width:992px) {
        margin-bottom: 40px;
    }
    @media only screen and (max-width:680px) {
        margin-bottom: 20px;
    }
    .sss-item{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        &.js-active{
            .sss-title{
                background: #00B4E5;
                color: #FFF;
                &:before{
                    transform: rotate(-180deg);
                    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.62' height='10.06' viewBox='0 0 18.62 10.06'%3E%3Cpath id='Path_2415' data-name='Path 2415' d='M16.5,8.25,8.249,0,0,8.25' transform='translate(17.559 9.31) rotate(180)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
                }
            }
            .sss-desc{
                display: block;
            }
        }
    }
    .sss-title{
        width: 100%;
        display: flex;
        background: #F8F8F8;
        font-size: 28px;
        font-weight: 300;
        color: #000000;
        position: relative;
        padding: 26px 26px 26px 80px;
        line-height: initial;
        transition: 0.5s;
        cursor: pointer;
        @media only screen and (max-width:1280px) {
            font-size: 22px;
        }
        @media only screen and (max-width:992px) {
            font-size: 18px;
        }
        @media only screen and (max-width:680px) {
            font-size: 16px;
            padding: 15px 15px 15px 50px;
        }
        &:before{
            content:"";
            position: absolute;
            top: 50%;
            left: 30px;
            background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.62' height='10.06' viewBox='0 0 18.62 10.06'%3E%3Cpath id='Path_2415' data-name='Path 2415' d='M16.5,8.25,8.249,0,0,8.25' transform='translate(17.559 9.31) rotate(180)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
            background-repeat: no-repeat no-repeat;
            background-position: center center;
            background-size: cover;
            width: 18.62px;
            height: 10.06px;
            margin-top: -5px;
            transition: 0.5s;
            @media only screen and (max-width:680px) {
                left: 15px;
            }
        }
        &:hover{
            background: #00B4E5;
            color: #FFF;
            &:before{
                transform: rotate(-180deg);
                background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.62' height='10.06' viewBox='0 0 18.62 10.06'%3E%3Cpath id='Path_2415' data-name='Path 2415' d='M16.5,8.25,8.249,0,0,8.25' transform='translate(17.559 9.31) rotate(180)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
            }
        }
    }
    .sss-desc{
        padding: 40px 60px 60px 80px;
        display: none;
        @media only screen and (max-width:992px) {
            padding: 30px 0;
        }
        @media only screen and (max-width:680px) {
            padding: 15px 0;
            font-size: 15px;
        }
    }
}
.dt-lnk{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width:680px) {
        margin-top: 20px;
    }
    a{
        margin-right: 20px;
        @media only screen and (max-width:680px) {
            margin-right: 0;
            margin-bottom: 15px;
        }
        &:last-child{
            margin-right: 0;
            @media only screen and (max-width:680px) {
                margin-bottom: 0;
            }
        }
    }
}
.border-lnk{
    border-radius: 24px;
    padding: 16px 60px;
    border: 1px solid #333E48;
    font-size: 17px;
    font-weight: 300;
    color: #333E48;
    text-decoration: none;
    line-height: initial;
    p{
        font-weight: 300;
    }
    @media only screen and (max-width:1280px) {
        padding: 15px 30px;
    }
    @media only screen and (max-width:992px) {
        padding: 15px 15px;
    }
    @media only screen and (max-width:680px) {
        width: 100%;
        text-align: center;
        padding: 13px 15px;
        font-size: 15px;
    }
    &.white{
        border-color: #FFF;
        color: #FFF;
    }
}
.background-lnk{
    border-radius: 24px;
    padding: 16px 60px;
    background: #333E48;
    font-size: 17px;
    font-weight: 300;
    color: #fff;
    text-decoration: none;
    line-height: initial;
    cursor: pointer;
    p{
        font-weight: 300;
    }
    @media only screen and (max-width:1280px) {
        padding: 15px 30px;
    }
    @media only screen and (max-width:992px) {
        padding: 15px 15px;
    }
    @media only screen and (max-width:680px) {
        width: 100%;
        text-align: center;
        font-size: 15px;
        padding: 13px 15px;
    }
    &.blue{
        background: #00B4E5;
        border: none;
    }
}
.career-full-lnk{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .item{
        width: 100%;
        padding: 30px 50px;
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        @media only screen and (max-width:680px){
            margin-bottom: 30px;
            padding: 15px 15px;
        }
        &.kariyer{
            background: #7715A4;
            .img{
                background: #8316B5;
            }
        }
        &.linkedin{
            background:#005B88;
            .img{
                background: #006699;
            }
        }
        .img{
            width: 32%;
            height: 220px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            @media only screen and (max-width:680px){
                width: 100%;
                height: 160px;
                padding-left: 0;
            }
            img{
                float: left;
                max-width: 80%;
                max-height: 80%;
            }
        }
        .box{
            width: 68%;
            padding-left: 5%;
            color: #FFF;
            margin-top: 15px;
            @media only screen and (max-width:680px){
                width: 100%;
                padding-left: 0;
            }
        }
    }
}
.cr-title{
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 30px;
    p{
        font-weight: 300;
    }
    @media only screen and (max-width:680px){
        margin-bottom: 20px;
        font-size: 20px;
    }
}
.contact-page {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .top{
        width: 100%;
        position: relative;
        padding: 120px 0 60px 0;
        @media only screen and (max-width:992px){
            padding: 60px 0;
        }
        @media only screen and (max-width:680px){
            padding: 30px 0;
        }
        // .bg{
        //     width: 640px;
        //     height: 100%;
        //     background-position: center center;
        //     background-size: cover;
        //     position: absolute;
        //     top: 0;
        //     right: calc((-1920px - -1400px) / 2);
        //     @media only screen and (max-width:1920px) {
        //         right: calc((-100vw - -1400px) / 2);
        //     }
        //     @media only screen and (max-width: 1600px) {
        //         right: calc((-100vw - -1185px) / 2);
        //     }
        //     @media only screen and (max-width: 1380px) {
        //         right: calc((-100vw - -977px) / 2);
        //         width: 400px;
        //     }
        //     @media only screen and (max-width: 992px) {
        //         display: none;
        //     }
        // }
    }
    .contact-title{
        font-size: 28px;
        color: #BBBBBB;
        margin: 0 0;
        width: 100%;
        @media only screen and (max-width:992px){
            font-size: 24px;
        }
        @media only screen and (max-width:680px){
            font-size: 20px;
        }
    }
    .map-address{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        @media only screen and (max-width:680px){
            flex-direction: column-reverse;
        }
        .address{
            width: 27%;
            @media only screen and (max-width:992px){
                width: 35%;
            }
            @media only screen and (max-width:680px){
                width: 100%;
                margin-top: 30px;
            }
            .title{
                font-size: 22px;
                color: #313033;
                margin-bottom: 30px;
                @media only screen and (max-width:680px){
                    font-size: 20px;
                    margin-bottom: 15px;
                }
            }
            .desc{
                margin-bottom: 20px;
                @media only screen and (max-width:680px){
                    margin-bottom: 15px;
                }
                strong{
                    width: 100%;
                    font-size: 15px;
                    font-weight: 500;
                    color: #313033;
                    margin-bottom: 5px;
                }
                div{
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #565656;
                    a{
                        text-decoration: none;
                        color: #565656;
                    }
                }
            }
            .directions{
                margin-top: 50px;
                @media only screen and (max-width:680px){
                    margin-top: 20px;
                }
                a{
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 15px;
                    font-weight: 600;
                    text-decoration: none;
                    span{
                        display: flex;
                        align-items: center;
                        align-content: center;
                        color: #ACACAC;
                        padding: 0 110px 0 20px;
                        border-bottom: 2px solid #DEDEDE;
                        @media only screen and (max-width:1380px){
                            padding: 0 60px 0 20px;
                        }
                    }
                    .sv{
                        svg{
                            float: left;
                        }
                    }
                }
            }
        }
        .map{
            padding-left: 100px;
            height: 500px;
            width: 73%;
            @media only screen and (max-width:992px){
                padding-left: 30px;
                width: calc(15px + 65%);
            }
            @media only screen and (max-width:680px){
                width: 100%;
                margin-left: 0;
                padding-left: 0;
                height: 240px;
            }
        }
    }
}
.contact-line{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 1px;
    background: #CBCBCB;
    margin: 120px 0;
    @media only screen and (max-width:992px){
        margin: 60px 0;
    }
    @media only screen and (max-width:680px){
        margin: 30px 0;
    }
}
.contact-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .form{
        width: 100%;
        margin: 60px 0;
        @media only screen and (max-width:992px){
            margin: 60px 0;
        }
        @media only screen and (max-width:680px){
            margin: 30px 0;
        }
        form{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .left{
            width: calc(50% - 20px);
            @media only screen and (max-width:680px){
                width: 100%;
            }
        }
        .inp, .input-file{
            width: 100%;
            position: relative;
            margin-bottom: 30px;
            @media only screen and (max-width:680px){
                margin-bottom: 15px;
            }
            &:last-child{
                margin-bottom: 0;
                @media only screen and (max-width:680px){
                    margin-bottom: 15px;
                }
                &:after{
                    content:"";
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    width: 30px;
                    height: calc(100% - 25px);
                    background: #FFF;
                }
            }
            &.input-file{
                &:after{
                    display: none !important;
                }
            }
            label{
                position: absolute;
                top: 50%;
                left: 20px;
                transform: translate(0, -50%);
                font-size: 15px;
                font-weight: 600;
                color: #313033;
                transition: 0.5s;
                @media only screen and (max-width:680px){
                    font-size: 14px;
                }
                span{
                    color: #EE1D23;
                }
                &.error{
                    display: none !important;
                }
            }
            input[type="number"]{
                -moz-appearance: textfield;
                &::-webkit-outer-spin-button{
                    -webkit-appearance: none;
                    margin: 0;
                }
                &::-webkit-inner-spin-button{
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
            input, textarea, select, .file-custom{
                width: 100%;
                height: 80px;
                border: 1px solid #CBCBCB;
                padding: 15px 15px 0 20px;
                outline: none;
                resize: none;
                font-size: 18px;
                color: #313033;
                border-radius: 10px;
                outline: none;
                background: #FFF;
                @media only screen and (max-width:680px){
                    font-size: 14px;
                    padding: 0 15px 10px 0 15px;
                }
                &.error{
                    border-color: #EE1D23;
                    & + label + .file-custom{
                        border-color: #EE1D23;
                    }
                }
                &:focus,&.show-text{
                    &+label, &+label+label{
                        font-size: 13px;
                        top: 10px;
                        transform: none;
                        opacity: 0.7;
                    }
                }
                @media only screen and (max-width:680px){
                    height: 55px;
                }
                &.file-custom{
                    display:flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    padding: 15px 20px;
                    .svg{
                        margin-right: 10px;
                    }
                    div{
                        font-size: 15px;
                        font-weight: 600;
                        color: #313033;
                        max-width: calc(100% - 50px);
                    }
                    @media only screen and (max-width:680px){
                        height: auto;
                        padding-bottom: 15px;
                    }
                }
            }
            select{
                padding: 0 15px;
                font-size: 15px;
                font-weight: 600;
                color: #313033;
                &+:after{
                    display: none;
                }
            }
            .custom-file-text{
                font-size: 15px;
                font-weight: 600;
                color: #313033;
                transition: 0.5s;
                @media only screen and (max-width:680px){
                    font-size: 14px;
                }
                span{
                    color: #EE1D23;
                }
            }
            .custom-file-desc{
                font-size: 16px;
                color: #000000;   
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                @media only screen and (max-width:680px){
                    font-size: 14px;
                    white-space: inherit;
                }
            }
            input[type='file']{
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
            textarea{
                height: 190px;
                padding: 35px 20px 15px 20px;
                &:focus,&.show-text{
                    &+label, &+label+label{
                        top: 18px;
                    }
                }
            }
        }
        .select-inp{
            &:after{
                display: none;
            }
            select{
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                padding-right: 30px;
                &::-ms-expand{
                    display: none;
                }
            }
            &:before{
                content:"";
                position: absolute;
                top: 50%;
                right: 0;
                width: 34px;
                height: 34px;
                transform: translate(0, -50%);
                background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                background-size: contain;
            }
        }
        .right{
            width: calc(50% - 20px);
            @media only screen and (max-width:680px){
                width: 100%;
            }
            .inp{
                label{
                    top: 17px;
                    transform: none;
                }
            }
        }
        .send-and-text {
            width: 100%;
            display: flex;
            align-items: center;
            @media only screen and (max-width:992px){
                flex-wrap: wrap;
            }
            .text{
                padding-right: 30px;
                font-size: 15px;
                color: #565656;
                a{
                    font-weight: 600;
                    color: #565656;
                }
                @media only screen and (max-width:992px){
                    width: 100%;
                    padding-right: 0;
                    margin-bottom: 15px;
                }
                @media only screen and (max-width:680px){
                    font-size: 14px;
                }
            }
            .button{
                @media only screen and (max-width:680px){
                    width: 100%;
                    button, input{
                        width: 100%;
                    }
                }
            }
        }
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
.news-page{
    width: 100%;
    background: #F7F7F7;
}
.news-list{
    width: 100%;
    padding: 120px 0;
    @media only screen and (max-width:992px) {
        padding: 60px 0;
    }
    @media only screen and (max-width:680px) {
        padding: 30px 0;
    }
    .list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 0;
    }
    .item{
        width: calc(50% - 15px);
        margin-bottom: 90px;
        @media only screen and (max-width:992px) {
            margin-bottom: 60px;
        }
        @media only screen and (max-width:680px) {
            margin-bottom: 30px;
            width: 100%;
        }
    }
    .box{
        @media only screen and (max-width:1300px) {
            padding:20px 30px 20px 20px;
        }
        &:after{
            @media only screen and (max-width:1300px) {
                width: 15px;
            }
        }
    }
}
.load-more{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: 60px 0;
    @media only screen and (max-width:680px) {
        margin: 30px 0;
    }
    button{
        border: 1px solid #4E4E4E;
        padding: 22px 100px;
        font-size: 16px;
        font-weight: 500;
        color: #1A2329;
        background: none;
        border-radius: 37px;
        cursor: pointer;
        @media only screen and (max-width:680px) {
            width: 100%;
            padding: 15px 15px;
        }
    }
}
.media-top-dt{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 80px 0 0 0;
    @media only screen and (max-width:992px){
        margin: 45px 0 0 0;
    }
    @media only screen and (max-width:680px){
        margin: 30px 0 0 0;
    }
    h2{
        margin: 0 0;  
        font-size: 36px;
        font-weight: 500;
        @media only screen and (max-width:992px){
            font-size: 30px;
        }
        @media only screen and (max-width:680px){
            font-size: 20px;    
        }
    }
    .lk{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        border-top: 1px solid #CBCBCB;
        padding-top: 15px;
        @media only screen and (max-width:992px){
            margin-top: 30px;
        }
        @media only screen and (max-width:680px){
            margin-top: 15px;
        }
        .date{
            font-size: 15px;
            font-weight: 600;
            color: #ACACAC;
            opacity: 0.7;
            @media only screen and (max-width:680px){
                font-size: 14px;
            }
        }
        .prev{
            .sv{
                float: left;
                display: flex;
                align-items: center;
                align-content: center;
                svg{
                    float: left;
                    @media only screen and (max-width:680px){
                        width: auto;
                    }
                }
            }
            .nt{
                padding: 0 0 0 20px;
                float: left;
                display: flex;
                align-items: center;
                align-content: center;
                font-size: 16px;
                font-weight: 500;
                color: #1A2329;
                @media only screen and (max-width:680px){
                    font-size: 14px;
                    padding: 0 15px;
                }
            }
            a{
                text-decoration: none;
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
}
.news-detail-desc {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    font-size: 20px;
    color: #7A7A7A;
    font-weight: 300;
    p{
        font-weight: 300;
    }
    @media only screen and (max-width:992px){
        margin-top: 40px;
    }
    @media only screen and (max-width:680px){
        margin-top: 15px;
        font-size: 16px;
    }
}
.news-desc{
    margin-bottom: 100px;
    font-size: 16px;
    @media only screen and (max-width:992px){
        margin-bottom: 60px;
    }
    @media only screen and (max-width:680px){
        margin-bottom: 30px;
        font-size: 15px;
    }
}
.detail-text-to-img {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .item{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 80px 0;
        @media only screen and (max-width:992px){
            margin: 60px 0;
        }
        @media only screen and (max-width:680px){
            margin: 15px 0;
        }
        .img{
            width: 45%;
            height: 360px;
            @media only screen and (max-width:680px){
                width: 100%;
                height: 240px;
                margin-top: 15px;
            }
        }
        .text{
            width: 55%;
            @media only screen and (max-width:680px){
                width: 100%;
            }
        }
        .title{
            font-size: 28px;
            margin-bottom: 30px;
            color: #313033;
            @media only screen and (max-width:680px){
                font-size: 20px;
                margin-bottom: 10px;
            }
        }
        .descs{
            font-size: 16px;
            color: #565656;
            line-height: 29px;
            @media only screen and (max-width:680px){
                font-size: 15px;
                line-height: 24px;
            }
        }
        &.to-left{
            @media only screen and (max-width:680px){
                flex-direction: column-reverse;
            }
            .text{
                padding-left: 50px;
                @media only screen and (max-width:680px){
                    padding-left: 0;
                }
            }
        }
        &.to-right{
            .text{
                padding-right: 50px;
                @media only screen and (max-width:680px){
                    padding-right: 0;
                }
            }
        }
    }
}
.news-full-img{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 30px;
    @media only screen and (max-width:680px){
        margin-bottom: 20px;
    }
    img{
        float: left;
    }
}
.search-result-page {
    width: 100%;
}
.result-search {
    width: 100%;
    background: #f9f9f9;
    padding: 90px 0;
    @media only screen and(max-width:680px){
        padding: 30px 0;
    }
    .box{
        width: 1000px;
        margin: 0px auto;
        position: relative;
        @media only screen and(max-width:1024px){
            width: 100%;
            padding: 0 15px;
        }
    }
    .lbl{
        font-size: 35px;
        font-weight: 500;
        @media only screen and(max-width:680px){
            font-size: 24px;
        }
    }
    .inp{
        width: 100%;
        position: relative;
        margin-top: 20px;
    }
    input{
        width: 100%;
        height: 90px;
        border: none;
        background: #FFF;
        padding: 0 100px 0 30px;
        outline: none;
        font-size: 33px;
        font-weight: 300;
        @media only screen and (max-width:992px) {
            height: 70px;
            font-size: 24px;
        }
        @media only screen and (max-width:680px) {
            font-size: 16px;
            height: 60px;
            padding: 0 45px 0 15px;
        }
    }
    button{
        position: absolute;
        top: 50%;
        right: 50px;
        background: none;
        outline: none;
        border: none;
        transform: translate(0, -50%);
        cursor: pointer;
        @media only screen and (max-width:680px) {
            right: 15px;
        }
        svg{
            float: left;
            @media only screen and (max-width:680px) {
                width: 20px;
            }
        }
    }
}
.result-list {
    width: 100%;
    padding: 40px 0;
    @media only screen and (max-width:680px) {
        padding: 30px 0;
    }
    .result-found{
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        color: #565656;
        @media only screen and (max-width:680px) {
            font-size: 16px;
        }
    }
    .list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        @media only screen and(max-width:992px){
            margin-top: 30px;
        }
        @media only screen and (max-width:680px) {
            margin-top: 15px;
        }
        .item{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 20px;
            a{
                width: 100%;
                border: 2px solid #F3F3F3;
                padding: 40px 50px;
                text-decoration: none;
                @media only screen and(max-width:992px){
                    padding: 30px 30px;
                }
                @media only screen and(max-width:680px){
                    padding: 15px 15px;
                }
            }
            .jb{
                width: 100%;
                font-size: 15px;
                font-weight: 600;
                color: #000;
                opacity: 0.7;
                @media only screen and(max-width:680px){
                    font-size: 14px;
                }
            }
            .text{
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 20px 0 25px 0;
                color: #313033;
                font-weight: 500;
                font-size: 20px;
                @media only screen and(max-width:992px){
                    text-overflow: initial;
                    white-space: inherit;
                }
                @media only screen and(max-width:680px){
                    margin: 15px 0;
                    font-size: 16px;
                }
            }
            .date{
                width: 360px;
                font-size: 15px;
                font-weight: 600;
                color: #ACACAC;
                opacity: 0.7;
                border-bottom: 2px solid #DEDEDE;
                padding-bottom: 15px;
                @media only screen and(max-width:680px){
                    max-width: 100%;
                    font-size: 14px;
                }
            }
        }
    }
}
.not-fount-page {
    width: 100%;
    margin-top: 85.36px;
    padding: 130px 0;
    @media only screen and(max-width:992px){
        margin-top: 81.78px;
    }
    @media only screen and(max-width:680px){
        padding: 60px 0;
    }
    .box{
        text-align: center;
        h1, h2, h3{
            margin:0 0;
            padding: 0 0;
        }
        h1{
            font-size: 180px;
            font-weight: 300;
            color: #C5C5C5;
            @media only screen and(max-width:680px){
                font-size: 120px;
            }
        }
        h2{
            font-size: 28px;
            margin-top: 40px;
            @media only screen and(max-width:680px){
                margin-top: 30px;
                font-size: 22px;
            }
        }
        h3{
            font-size: 16px;
            color: #565656;
            margin-top: 40px;
            @media only screen and(max-width:680px){
                margin-top: 30px;
                font-size: 14px;
            }
        }
    }
}

.career-form{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    display: none;
    .inp{
        width: calc(43% - 20px);
        margin-right: 20px;
        @media only screen and (max-width:680px){
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
        }
        input, select{
            width: 100%;
            height: 65px;
            border-radius: 33px;
            padding:0 20px;
            outline: none;
            border: none;
            font-size: 18px;
            border: 2px solid #E9E9E9;
            color: #565656;
            &::placeholder{
                color: #565656;
            }
            &::-moz-placeholder{
                color: #565656;
            }
            &::-webkit-input-placeholder{
                color: #565656;
            }
            @media only screen and (max-width:992px){
                font-size: 14px;
            }
            @media only screen and (max-width:680px){
                height: 50px;
                padding: 0 15px;
            }
        }
    }
    .btn{
        width: 14%;
        @media only screen and (max-width:680px){
            width: 100%;
        }
        button{
            width: 100%;
            padding: 20px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            background: #00B4E5;
            border-radius: 33px;
            border: none;
            outline: none;
            cursor: pointer;
            @media only screen and (max-width:680px){
                padding: 13px 15px;
            }
            svg{
                float: left;
                margin-right: 20px;
            }
            span{
                font-size: 15px;
                color: #FFF;
            }
        }
    }
}
.career-result-text{
    width: 100%;
    display: flex;
    margin: 20px 0 50px 0;
    font-size: 16px;
    @media only screen and (max-width:680px){
        margin: 20px 0;
    }
    strong{
        margin-right: 8px;
    }
}
.career-result-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;
    .top{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 2px solid #DCDCDC;
        padding-bottom: 16px;
        font-size: 15px;
        font-weight: 600;
        color: #313033;
        .item{
            width: 40.5%;
            font-weight: 500;
            &:last-child{
                width: 19%;
            }
        }
        @media only screen and (max-width:680px){
            display: none;
        }
    }
    .list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-size: 20px;
        font-weight: 500;
        color: #313033;
        @media only screen and (max-width:992px){
            font-size: 16px;
        }
        .mobile-title{
            display: none;
            @media only screen and (max-width:680px){
                display: block;
                width: 100%;
                font-weight: 600;
            }
        }
        .box{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border-bottom: 2px solid #DCDCDC;
            .item{
                width: 40%;
                padding: 25px 0;
                display: flex;
                flex-wrap: wrap;
                font-size: 16px;
                @media only screen and (max-width:680px){
                    width: 100%;
                    padding: 10px 0;
                    font-size: 15px;
                }
                &:nth-child(3n){
                    width: 19%;
                    @media only screen and (max-width:680px){
                        width: 100%;
                    }
                }
                &:last-child{
                    width: 19%;
                    @media only screen and (max-width:680px){
                        width: 100%;
                    }
                }
                .img{
                    width: 100%;
                    display: none;
                }
                img{
                    float: left;
                }
                .mp{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    width: calc(50% - 7px);
                    margin-right: 7px;
                    border-radius: 27px;
                    height: 38px;
                    @media only screen and (max-width:320px){
                        width: 100%;
                        margin-top: 15px;
                        &:first-child{
                            margin-top: 0;
                        }
                    }
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        height: 100%;
                        width: 100%;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    img{
                        max-width: 60%;
                        max-height: 55%;
                    }
                    &.kariyer{
                        background: #8316B5;
                    }
                    &.linkedin{
                        background: #006192;
                    }
                }
            }
        }
    }
}
h3.p2-desc{
    font-size: 21px;
    font-weight: 500;
    color: #000;
    margin-bottom: 60px;
    @media only screen and (max-width:992px) {
        margin-bottom: 30px;
        font-size: 17px;
    }
}
.desc-half-item{
    width: 100%;
    display: flex;
    margin-top: 60px;
    @media only screen and (max-width:992px) {
        margin-top: 30px;
        flex-wrap: wrap;
    }
    .img-s{
        width: 50%;
        margin-top: 45px;
        @media only screen and (max-width:992px) {
            width: 100%;
            margin-top: 20px;
        }
        img{
            max-width: 100%;
            float: left;
            &~img{
                margin-top: 50px;
                @media only screen and (max-width:992px) {
                    margin-top: 20px;
                }
            }
            &+div{
                margin-top: 20px;
                display: inline-block;
                @media only screen and (max-width:992px) {
                    margin-top: 10px;
                }
            }
        }
        &:first-child{
            margin-top: 0;
        }
    }
    .dsc-s{
        width: 50%;
        padding-left: 40px;
        font-weight: 300;
        @media only screen and (max-width:992px) {
            width: 100%;
            padding-left: 0;
            margin-top: 20px;
        }
    }
    &.to-scrolling{
        .dsc-s{
            padding-right: 15px;
            max-height: 430px;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-track {
                background: #EEEEEE; 
            }
            &::-webkit-scrollbar-thumb {
                background: #B2B2B2; 
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #B2B2B2; 
            }
        }
    }
}
.dt-full-text {
    .desc-half-item{
        margin-top: 150px;
        @media only screen and (max-width:992px) {
            margin-top: 60px;
        }
        &+.desc-half-item{
            margin-top: 150px;
            @media only screen and (max-width:992px) {
                margin-top: 60px;
            }
        }
        &.margin-80{
            margin-top: 80px;
            @media only screen and (max-width:992px) {
                margin-top: 30px;
            }
        }
        &:first-of-type{
            margin-top: 60px;
            @media only screen and (max-width:992px) {
                margin-top: 30px;
            }
        }
    }
}
.dt-two-full-img{
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
    @media only screen and (max-width:992px) {
        margin-top: 30px;
    }
    div{
        width: calc(50% - 20px);
        display: flex;
        flex-wrap: wrap;
        @media only screen and (max-width:992px) {
            width: 100%;
            margin-top: 20px;
        }
        img{
            float: left;
        }
        span{
            width: 100%;
            display: block;
            margin-top: 20px;
            @media only screen and (max-width:992px) {
                margin-top: 10px;
            }
        }
    }
}
.full-two-img{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 120px;
    @media only screen and (max-width:1200px) {
        margin-top: 60px;
    }
    @media only screen and (max-width:680px) {
        margin-top: 30px;
    }
    .item{
        width: calc(50% - 20px);
        height: 220px;
        background-position: center center;
        background-size: cover;
        margin-top: 100px;
        @media only screen and (max-width:680px) {
            width: 100%;
            margin-top: 20px;
        }
        &:nth-child(1){
            margin-top: 0;
        }
        &:nth-child(2){
            margin-top: 0;
            @media only screen and (max-width:680px) {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}
.full-two-img-to-text{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 120px;
    @media only screen and (max-width:1200px) {
        margin-top: 60px;
    }
    @media only screen and (max-width:680px) {
        margin-top: 30px;
    }
    .item{
        width: 50%;
        height: 260px;
        background-position: center center;
        background-size: cover;
        position: relative;
        @media only screen and (max-width:680px) {
            width: 100%;
            height: 240px;
        }
        .name{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 20px 25px;
            background: rgba(0,0,0,0.7);
            font-size: 17px;
            font-weight: 500;
            color: #FFF;
            @media only screen and (max-width:680px) {
                font-size: 14px;
                padding: 15px 15px;
            }
            p{
                margin: 0 0;
                padding: 0 0;
            }
        }
    }
}