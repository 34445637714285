// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------
.u-font-size-2xsmall {
  font-size: 0.625rem;
}

.u-font-size-xsmall {
  font-size: 0.75rem;
}

.u-font-size-small,
small {
  font-size: 0.875rem;
}

.u-font-size-regular {
  font-size: 1rem;
}

.u-font-size-medium {
  font-size: 1.125rem;
}

.u-font-size-large {
  font-size: 1.25rem;
}

.u-font-size-xlarge {
  font-size: 1.5rem;
}
