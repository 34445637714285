// -----------------------------------------------------------------------------
// Footer
// -----------------------------------------------------------------------------
footer{
  width: 100%;
  padding: 90px 0 80px 0;
  @media only screen and (max-width:992px) {
      padding: 60px 0;
  }
  @media only screen and (max-width:680px) {
    padding: 30px 0;
  }
  a{
    transition: 0.5s;
    &:hover{
      opacity: 0.6;
    }
  }
  .footer-logo{
    width: 100%;
    display: flex;
    justify-content: center;
    img, svg{
      float: left;
    }
  }
  .footer-social {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 40px;
    @media only screen and (max-width:680px) {
      margin-top: 30px;
    }
    a{
      width: 42px;
      height: 42px;
      border-radius: 100%;
      border: 1px solid rgba(26,24,24,0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 12.5px;
      @media only screen and (max-width:680px) {
        margin: 0 5px;
        width: 35px;
        height: 35px;
      }
      svg{
        float: left;
        @media only screen and (max-width:680px) {
          max-width: 14px;
        }
      }
    }
  }
  .footer-menu{
    display: flex;
    margin-top: 50px;
    justify-content: center;
    @media only screen and (max-width:680px) {
      margin-top: 20px;
    }
    ul{
      margin: 0 0;
      padding: 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      li{
        float: left;
        list-style: none;
        padding: 0 27px;
        @media only screen and (max-width:992px) {
          padding: 0 10px;
        }
        @media only screen and (max-width:680px) {
          width: 100%;
          padding: 10px 0;
        }
        a{
          text-decoration: none;
          color: #000000;
          font-size: 13px;
          @media only screen and (max-width:680px) {
            padding: 5px 0;
            text-align: center;
            display: block;
          } 
        }
      }
    }
  }
  .footer-sub-menu{
    margin-top: 35px;
    ul{
      margin: 0 0;
      padding: 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      li{
        float: left;
        list-style: none;
        padding: 0 5px;
        color: #959595;
        font-size: 12px;
        a{
          color: #959595;
          text-decoration: none;
          &:hover{
            color: #000;
            opacity: 1;
          }
        }
      }
    }
  }
  .footer-copyright{
    margin-top: 50px;
    font-size: 11px;
    color: #666666;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    @media only screen and (max-width:680px) {
      margin-top: 30px;
      p{
        margin:0 0;
      }
    }
    a{
      text-decoration: none;
      color: #666666;
    }
    .copy{
      padding: 0 120px;
      @media only screen and (max-width:680px) {
        padding: 0 0;
      }
    }
    .bp{
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      font-size: 11px;
      color: #666666;
      @media only screen and (max-width:680px) {
        width: 100%;
        position: relative;
        transform: none;
        top: auto;
        right: auto;
        margin-top: 20px;
      }
      a{
        text-decoration: none;
      }
      span{
        line-height: initial;
      }
      svg{
        float: left;
        margin-left:13px;
      }
    }
  }
}
.cookie-policy{
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1920px;
  margin: 0px auto;
  background: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 11px 20px;
  z-index: 15;
  display: none;
  @media only screen and (max-width:1940px) {
      width: 100%;
  }
  p{
    margin: 0 0;
  }
  .left{
    float: left;
    font-size: 12px;
    color: #969696;
    a{
      margin-left: 7px;
      color: #FFF;
      text-decoration: none;
    }
  }
  .accept-cookie{
    float: right;
    font-size: 12px;
    color: #FFF;
    text-decoration: underline;
    cursor: pointer;
  }
}
.page-to-top {
  position:fixed;
  bottom:120px;
  right: 30px;
  svg{
    float:left;
  }
}