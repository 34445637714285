body{
    padding-top: 100px;
    @media only screen and (max-width: 992px) {
        padding-top: 60px;
    }
}
* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.main-wrapper{
    width: 1920px;
    margin: 0px auto;
    overflow: hidden;
    @media only screen and (max-width:1940px) {
        width: 100%;
    }
}
.home-slider {
    width: 100%;
    height: calc(100vh - 100px);
    background: #000;
    display: flex;
    overflow: hidden;
    position: relative;
    @media only screen and (max-width:992px) {
        height: auto;
        flex-wrap: wrap;
    }
    .video-container{
        width: 100%;
        height: 100%;
        position: relative;
        .video-bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            z-index: 0;
        }
    }
    .home-main-slider{
        width: 100%;
        height: calc(100% - 240px);
        overflow: hidden;
        @media only screen and (max-width:1380px) {
            height: calc(100% - 200px);
        }
        @media only screen and (max-width:992px) {
            height: 550px;
        }
        @media only screen and (max-width:680px) {
            height: 340px;
        }
        .swiper-slide{
            width: 100%;
            height: 100%;
            background-position: center center;
            background-size: cover;
        }
    }
}
.home-watch-video{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #333E48;
    z-index: 3;
    height: 240px;
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width:1380px) {
        height: 200px;
    }
    @media only screen and (max-width:992px) {
        height: auto;
        padding-bottom: 30px;
        position: relative;
        bottom: auto;
        left: auto;
    }
    @media only screen and (max-width:680px) {
        position: relative;
        bottom: auto;
        left: auto;
    }
    .o-container{
        display: flex;
        justify-content: flex-start;
        @media only screen and (max-width:1200px) {
            max-width: 1120px;
        }
        @media only screen and (max-width:992px) {
            max-width: 100%;
            flex-wrap: wrap;
        }
    }
    .list{
        width: 77.5%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        padding: 37px 0;
        @media only screen and (max-width:1380px) {
            padding: 20px 0;
        }
        @media only screen and (max-width:992px) {
            width: 100%;
            height: auto;
            padding: 30px 0 0 0;
        }
    }
    .home-main-thumb-slider{
        width: 100%;
        overflow: hidden;
        .swiper-slide{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            padding-right: 2px;
        }
        .left{
            width: 69%;
            @media only screen and (max-width:992px) {
                width: 100%;
            }
        }
        .sp-btn{
            a{
                margin-right: 2.5%;
                @media only screen and (max-width:992px) {
                    margin-right: 0;
                }
                &:hover{
                    margin-right: 0;
                }
            }
        }
    }
    .home-thumb-navigation{
        width: 16%;
        margin-left: 6.5%;
        margin-top: 80px;
        @media only screen and (max-width:1430px) {
            margin-left: 5%;
        }
        @media only screen and (max-width:992px) {
            width: 100%;
            background:#333E48;
            margin-left: 0;
            margin-top: 20px;
        }
        .arrow-nav{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            flex-direction:row-reverse;
        }
        .arrow{
            width: 108px;
            display: flex;
            justify-content: space-between;
            .arr{
                width: 51px;
                height: 51px;
                border: 1px solid rgba(255,255,255,0.14);
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                cursor: pointer;
                transition: 0.5s;
                svg{
                    float: left;
                }
                &:hover{
                    background: rgba(255,255,255,0.14);
                }
            }
        }
        .home-th-nav{
            font-size: 15px;
            color: #FFF;
            opacity: 0.6;
        }
        .home-th-pagination{
            width: 100%;
            height: 1px;
            position: relative;
            margin-top: 30px;
            background: rgba(255,255,255,0.2);
            .swiper-pagination-progressbar-fill{
                background: #FFF;
            }
            @media only screen and (max-width:992px) {
                display: none;
            }
        }
    }
}
.sp-title{
    font-size: 34px;
    font-weight: 300;
    color: #FFF;
    line-height: 40px;
    @media only screen and (max-width:1670px) {
        font-size: 30px;
        line-height: 36px;
    }
    @media only screen and (max-width:1430px) {
        font-size: 26px;
        line-height: 32px;
    }
    @media only screen and (max-width:1380px) {
        font-size: 24px;
        line-height: 30px;
    }
    @media only screen and (max-width:1280px) {
        font-size: 20px;
        line-height: 26px;
    }
    @media only screen and (max-width:680px) {
        font-size: 18px;
        line-height: 24px;
    }
}
.sp-desc{
    font-size: 17px;
    color: #FFF;
    opacity: 0.6;
    margin-top: 20px;
    @media only screen and (max-width:1380px) {
        font-size: 15px;
        margin-top: 15px;
    }
    @media only screen and (max-width:1280px) {
        font-size: 14px;
    }
    @media only screen and (max-width:680px) {
        font-size: 13px;
    }
}
.sp-btn{
    width: 24%;
    @media only screen and (max-width:992px) {
        width: 100%;
        margin-top: 20px;
    }
    a{
        display: flex;
        justify-content: center;
        float: right;
        width: 100%;
        font-size: 17px;
        font-weight: 300;
        text-decoration: none;
        color: #333E48;
        border-radius: 24px;
        padding: 13px 15px;
        background: #FFF;
        transition: 0.5s;
        &:hover{
            width:105%;
            @media only screen and (max-width:992px) {
                width: 100%;
            }
        }
        @media only screen and (max-width:680px) {
            padding: 10px 15px;
            font-size: 15px;
        }
    }
}
.home-sl-watch-video{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 21%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: none;
    @media only screen and (max-width:1780px) {
        width: 18%;
    }
    @media only screen and (max-width:1680px) {
        width: 20%;
    }
    @media only screen and (max-width:1430px) {
        width: 23%;
    }
    @media only screen and (max-width:992px) {
        display: none;
    }
    &:before{
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00B4E5;
        opacity: 0.4;
        transition: 0.5s;
    }
    a{
        display: flex;
        align-items: center;
        align-content: center;
        padding: 13px 50px 13px 40px;
        text-decoration: none;
        background: #00B4E5;
        border-radius: 24px;
        color: #FFF;
        font-size: 17px;
        font-weight: 300;
        position: relative;
        transition: 0.5s;
        @media only screen and (max-width:1380px) {
            padding: 13px 20px;
        }
        svg{
            float: left;
            margin-right: 20px;
        }
        span{
            font-weight: 300;
        }
    }
    &:hover{
        &:before{
            opacity: 0;
        }
        a{
            padding: 13px 60px 13px 50px;
            @media only screen and (max-width:1380px) {
                padding: 13px 30px;
            }
        }
    }
}
.home-capabilities{
    width: 100%;
    padding: 110px 0 130px 0;
    @media only screen and (max-width:1380px) {
        padding: 60px 0;
    }
    @media only screen and (max-width:680px) {
        padding: 30px 0;
    }
    .list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 100px;
        overflow: hidden;
        height: 560px;
        @media only screen and (max-width:1380px) {
            margin-top: 60px;
            height: 520px;
        }
        @media only screen and (max-width:992px) {
            height: auto;
            margin-top:30px;
        }
        @media only screen and (max-width:680px) {
            margin-top: 20px;
        }
        .item{
            width: calc(100% / 3);
            height: 100%;
            background-position: center center;
            background-size: cover;
            position: relative;
            transition: 0.5s;
            @media only screen and (max-width:992px) {
                width: 50%;
                height: 240px;
            }
            @media only screen and (max-width:680px) {
                width: 100%;
            }
            &:before{
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.5);
                transition: 0.5s;
            }
            &:hover{
                width: 35% !important; 
                @media only screen and (max-width:992px) {
                    width: 50% !important;
                }
                @media only screen and (max-width:680px) {
                    width: 100% !important;
                }
                &:before{
                    background: rgba(0,0,0,0.5) !important;
                }
            }
        }
        a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            text-decoration: none;
            .img{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;
                margin-bottom: 40px;
                img{
                    float: left;
                }
            }
            .nm{
                font-size: 21px;
                color: #FFF;
                width: 100%;
                padding: 0 30px;
                text-align: center;
            }
        }
        &:hover{
            .item{
                width: 32.5%;
                @media only screen and (max-width:992px) {
                    width: 50%;
                }
                @media only screen and (max-width:680px) {
                    width: 100%;
                }
                &:before{
                    background: rgba(0,0,0,0.7);
                }
            }
        }
    }
}
.home-solutions{
    width: 100%;
    padding: 110px 0 130px 0;
    background: #333E48;
    @media only screen and (max-width:1380px) {
        padding: 60px 0;
    }
    @media only screen and (max-width:680px) {
        padding: 30px 0;
    }
    .hmt-title{
        color: #FFFFFF;
    }
    .list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 100px;
        overflow: hidden;
        @media only screen and (max-width:1380px) {
            margin-top: 60px;
        }
        @media only screen and (max-width:992px) {
            margin-top:30px;
        }
        @media only screen and (max-width:680px) {
            margin-top: 20px;
        }
        .item{
            width: calc(100% / 3);
            height: 560px;
            background-position: center center;
            background-size: cover;
            position: relative;
            transition: 0.5s;
            @media only screen and (max-width:992px) {
                width: 50%;
                height: 240px;
            }
            @media only screen and (max-width:680px) {
                width: 100%;
            }
            &:before{
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.5);
                transition: 0.5s;
            }
            &:hover{
                width: 35% !important; 
                @media only screen and (max-width:992px) {
                    width: 50% !important;
                }
                @media only screen and (max-width:680px) {
                    width: 100% !important;
                }
                &:before{
                    background: rgba(0,0,0,0.5) !important;
                }
            }
        }
        a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            text-decoration: none;
            .img{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;
                margin-bottom: 40px;
                img{
                    float: left;
                }
            }
            .nm{
                font-size: 21px;
                color: #FFF;
                width: 100%;
                padding: 0 30px;
            }
        }
        &:hover{
            .item{
                width: 32.5%;
                @media only screen and (max-width:992px) {
                    width: 50%;
                }
                @media only screen and (max-width:680px) {
                    width: 100%;
                }
                &:before{
                    background: rgba(0,0,0,0.7);
                }
            }
        }
    }
}
.top-arrow-dist{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (max-width:680px) {
        margin-bottom: 30px;
    }
    .carousel-arrow{
        width: 103px;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width:680px) {
            width: auto;
            margin-top: 15px;
        }
        .arr{
            width: 51px;
            height: 51px;
            background: #333E48;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            cursor: pointer;
            @media only screen and (max-width:680px) {
                width: 32px;
                height: 32px;
            }
            svg{
                float: left;
                @media only screen and (max-width:680px) {
                    width: 7px;
                    height: auto;
                }
            }
        }
    }
}
.home-news{
    padding: 120px 0 140px 0;
    display: flex;
    flex-wrap: wrap;
    background: #EDEEF0;
    width: 100%;
    overflow: hidden;
    @media only screen and (max-width:992px) {
        padding: 60px 0;
    }
    @media only screen and (max-width:680px) {
        padding: 30px 0;
    }
}
.news-dt{
    .hm-top{
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 143px);
        justify-content: space-between;
        @media only screen and (max-width:680px) {
            width: 100%;
        }
        .hmt-title{
            width: calc(100% - 200px);
            @media only screen and (max-width:680px) {
                width: 100%;
            }
        }
    }
    .list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 80px;
        @media only screen and (max-width:1380px) {
            margin-top: 60px;
        }
        @media only screen and (max-width:992px) {
            margin-top:30px;
        }
        @media only screen and (max-width:680px) {
            margin-top: 20px;
        }
    }
    .home-news-carousel{
        width: 100%;
        overflow: hidden;
    }
    a{
        text-decoration: none;
        &:hover{
            .img{
                .bx{
                    width:110%;
                    height: 110%;
                    margin-left: -5%;
                    margin-top: -5%;
                }
            }
            .box{
                width: 86%;
                padding: 40px 13% 40px 40px;
                @media only screen and (max-width:1280px) {
                    padding: 20px 13% 20px 20px;
                }
                @media only screen and (max-width:992px) {
                    width:100%;
                    padding: 15px 15px;
                }
            }
        }
    }
    .img{
        width: 100%;
        height: 510px;
        background-position: center center;
        background-size: cover;
        position: relative;
        overflow: hidden;
        @media only screen and (max-width:1280px) {
            height: 460px;
        }
        @media only screen and (max-width:992px) {
            height: 340px;
        }
        @media only screen and (max-width:680px) {
            height: 220px;
        }
        .bx{
            position: absolute;
            background-position: center center;
            background-size: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: 0.5s;
        }
    }
    .box{
        width: 83%;
        margin-left: 20px;
        margin-top: -115px;
        padding: 40px 10% 40px 40px;
        position: relative;
        background: #FFF;
        transition: 0.5s;
        @media only screen and (max-width:1280px) {
            padding: 20px 10% 20px 20px;
        }
        @media only screen and (max-width:992px) {
            margin-top: 0;
            width: 100%;
            margin-left: 0;
            padding: 15px 15px;
        }
        .title{
            font-size: 17px;
            font-weight: 500;
            color: #313033;
            @media only screen and (max-width:1280px) {
                font-size: 16px;
            }
            @media only screen and (max-width:992px) {
                font-size: 14px;
            }
        }
        .desc{
            margin-top: 14px;
            font-size: 18px;
            font-weight: 500;
            color: #313033;
            @media only screen and (max-width:1280px) {
                font-size: 16px;
            }
            @media only screen and (max-width:992px) {
                font-size: 14px;
            }
        }
        .date{
            margin-top: 40px;
            width: 100%;
            border-bottom: 2px solid #DEDEDE;
            font-size: 15px;
            color: #ACACAC;
            padding-bottom: 15px;
            @media only screen and (max-width:992px) {
                font-size: 13px;
                margin-top: 20px;
            }
        }
    }
}
.home-career {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #EDEEF0;
    position: relative;
    @media only screen and (max-width:992px) {
        padding: 30px 0;
    }
    @media only screen and (max-width:680px) {
        flex-direction: column-reverse;
    }
    .left{
        width: 50%;
        min-height: 540px;
        padding: 30px 10% 30px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        @media only screen and (max-width:992px) {
            padding: 15px 5% 15px 0;
        }
        @media only screen and (max-width:680px) {
            width: 100%;
            padding: 20px 0 0 0;
            min-height: initial;
        }
        .box{
            width: 100%;
        }
        .desc{
            font-size: 17px;
            margin-top: 50px;
            font-weight: 300;
            p{
                font-weight: 300;
            }
            @media only screen and (max-width:680px) {
                margin-top: 20px;
                font-size: 16px;
            }
        }
    }
    .right{
        width: 50%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        position: absolute;
        top: 0;
        right: 0;
        @media only screen and (max-width:680px) {
            width: 100%;
            position: relative;
            top: auto;
            left: auto;
            height: 240px;
        }
    }
}
.career-lnk{
    width: 100%;
    margin-top: 65px;
    display: flex;
    @media only screen and (max-width:680px) {
        margin-top: 20px;
    }
    a{
        display: flex;
        padding: 13px 65px;
        border-radius: 24px;
        background: #333E48;
        text-decoration: none;
        color: #FFF;
        font-size: 17px;
        font-weight: 300;
        transition: 0.5s;
        @media only screen and (max-width:680px) {
            padding: 11px 15px;
            width: 100%;
            text-align: center;
            font-size: 15px;
            justify-content: center;
        }
        &:hover{
            padding: 13px 75px;
            @media only screen and (max-width:680px) {
                padding: 11px 15px;
            }
        }
    }
}

.home-catalogue{
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    padding: 180px 0;
    @media only screen and (max-width:992px) {
        padding: 60px 0;
    }
    @media only screen and (max-width:680px) {
        padding: 45px 0;
    }
    &:before{
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #071827;
        opacity: 0.8;
        transition: 0.5s;
    }
    &:hover{
        &:before{
            opacity: 0.7;
        }
    }
    .o-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .box{
        width: 50%;
        position: relative;
        text-align: center;
        @media only screen and (max-width:680px) {
            width: 100%;
        }
    }
    .img{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        opacity: 0.7;
        img{
            float: left;
        }
    }
    .desc{
        margin-top: 28px;
        font-size: 17px;
        color: #FFF;
        @media only screen and (max-width:680px) {
            margin-top: 15px;
            font-size: 15px;
        }
    }
}
.donate-title{
    font-size: 34px;
    font-weight: 300;
    color: #FFF;
    @media only screen and (max-width:680px) {
        font-size: 24px;
    }
}
.vfl-lnk{
    margin-top: 35px;
    display: flex;
    justify-content: center;
    @media only screen and (max-width:680px) {
        margin-top: 15px;
    }
    a{
        display: flex;
        align-items: center;
        align-content: center;
        padding: 16px 40px;
        border-radius: 25px;
        background: #FFF;
        color: #000000;
        text-decoration: none;
        font-size: 17px;
        line-height: initial;
        transition: 0.5s;
        @media only screen and (max-width:680px) {
            font-size: 15px;
            padding: 11px 30px;
        }
        svg{
            float: left;
            margin-right: 18px;
            @media only screen and (max-width:680px) {
                margin-right: 15px;
            }
        }
        span{
            line-height: initial;
            font-weight: 300;
        }
        &:hover{
            padding: 16px 50px;
        }
    }
}
.home-heat-simulation{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 160px 0 200px 0;
    @media only screen and (max-width:1380px) {
        padding: 60px 0;
    }
    @media only screen and (max-width:680px) {
        padding: 30px 0;
    }
    .list{
        width: 100%;
        margin-top: 80px;
        @media only screen and (max-width:1380px) {
            margin-top: 60px;
        }
        @media only screen and (max-width:992px) {
            margin-top:30px;
        }
        @media only screen and (max-width:680px) {
            margin-top: 20px;
        }
        .item{
            width: 100%;
            display: none;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            &.active{
                display: flex;
            }
            .left{
                width: 39.7%;
                display: flex;
                flex-wrap: wrap;
                .deg{
                    width: 50%;
                    border-left: 1px solid #E6E6E6;
                    border-radius: 12px;
                    height: 160px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: flex-end;
                    align-content: flex-end;
                    padding-left: 20px;
                    position: relative;
                    color: #333E48;
                    &:before{   
                        content:"";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 1px;
                        height: 40%;
                        background: #333E48;
                    }
                    &.first{
                        margin-bottom: 60px;
                        color: #A4D2DF;
                        &:before{
                            background: #A4D2DF;
                        }
                    }
                    .tp{
                        width: 100%;
                        display: flex;
                        .num{
                            font-size: 54px;
                            font-weight: 300;
                            @media only screen and (max-width:1430px) {
                                font-size: 48px;
                            }
                        }
                        .c{
                            font-size: 13px;
                            margin-left: 20px;
                        }
                    }
                    .bt{
                        width: 100%;
                        font-size: 17px;
                        font-weight: 500;
                    }
                }
            }
            .right{
                width: 57.02%;
                .img{
                    width: 100%;
                    position: relative;
                    float: left;
                }
                video{
                    width: 100%;
                    position: relative;
                    float: left;
                }
                .point{
                    position: absolute;
                    width: 26px;
                    height: 26px;
                    .cursor{
                        width: 26px;
                        height: 26px;
                        border: 1px solid #00B4E5;
                        border-radius: 100%;
                        position: relative;
                        transition: 0.5s;
                        margin-left: -13px;
                        margin-top: -13px;
                        z-index: 1;
                        &:after{
                            content:"";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 8px;
                            height: 8px;
                            transform: translate(-50%, -50%);
                            background: #00B4E5;
                            border-radius: 100%;
                        }
                        &:hover{
                            width: 76px;
                            height: 76px;
                            margin-left:-38px;
                            margin-top: -38px;
                            &+.line{
                                opacity: 1;
                                visibility: visible;
                                height: 170px;
                                &+.desc{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    .line{
                        position: absolute;
                        bottom: 100%;
                        left: -0.5px;
                        width: 1px;
                        height: 0;
                        background: #00B4E5;
                        transition: 0.5s;
                        opacity: 0;
                        visibility: hidden;
                    }
                    .desc{
                        position: absolute;
                        bottom: 196px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 580px;
                        -webkit-box-shadow: 0px 12px 37px 0px rgba(0,0,0,0.05);
                        -moz-box-shadow: 0px 12px 37px 0px rgba(0,0,0,0.05);
                        box-shadow: 0px 12px 37px 0px rgba(0,0,0,0.05);
                        background: #FFF;
                        display: flex;
                        flex-wrap: wrap;
                        min-height: 170px;
                        opacity: 0;
                        visibility: hidden;
                        transition: 0.5s;
                        .img-s{
                            width: 42%;
                            background-position: center center;
                            background-size: cover;
                        }
                        .dd{
                            width: 58%;
                            padding: 30px 30px 30px 38px;
                            .nm{
                                font-size: 22px;
                            }
                            .nd{
                                font-size: 14px;
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    .simulation-tab{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 120px;
        a{
            min-width: 220px;
            height: 50px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            background: #ECECEC;
            border-radius: 24px;
            padding: 0 30px;
            text-decoration: none;
            color: #000000;
            margin: 0 10px;
            font-size: 17px;
            transition: 0.5s;
            span{
                font-weight: 300;
            }
            .sv{
                margin-right: 15px;
            }
            svg{
                float: left;
                transition: 0.5s;
                path{
                    transition: 0.5s;
                }
            }
            &:hover, &.active{
                background: #333E48;
                color: #FFF;
                svg{
                    path{
                        fill: #FFF;
                    }
                }
            }
        }
    }
}